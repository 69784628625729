import { DocumentType, Work } from "@biblioteksentralen/cordata";
import { sift, unique } from "radash";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">, catalogueId?: string): DocumentType[] =>
  unique(
    (expressions ?? []).flatMap(({ manifestations = [], aggregateManifestations = [] }) =>
      sift(
        [...manifestations, ...aggregateManifestations]
          .filter(
            ({ libraryCatalogueRecords }) =>
              !catalogueId ||
              libraryCatalogueRecords?.find(
                (libraryCatalogueRecord) => libraryCatalogueRecord.catalogueId === catalogueId
              )
          )
          .flatMap(({ documentType }) => documentType)
      )
    ),
    getDocumentTypeLabel
  );

export const getDocumentTypeLabel = ({ medium, format }: DocumentType): string => {
  if (medium === "strømningsbar video") return "Strømmevideo";
  if (medium && ["Film", "Musikk", "Dataspill"].includes(format)) return medium;
  if (medium === "kassett") return `Kassett ${format}`;
  return format;
};

export const getFormatOrderIndex = (
  format: DocumentType["format"] | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

export const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getTranslatedDocumentTypeLabel = (documentTypeLabel: string, locale: "nb" | "nn") =>
  documentTypeLabelLanguageMap[documentTypeLabel.toLowerCase()]?.[locale] ?? documentTypeLabel;
const documentTypeLabelLanguageMap: Record<string, { nb: string; nn: string }> = {
  tegneserie: { nb: "tegneserie", nn: "teikneserie" },
  strømmevideo: { nb: "strømmevideo", nn: "straumevideo" },
};
