import { getCordataWemTriples } from "@biblioteksentralen/bmdb-search-client";
import { formatIsbdTitle, isMovingImageWork, Work } from "@biblioteksentralen/cordata";
import { normalizeIsbn } from "@libry-content/common";
import { sift, sort, unique } from "radash";
import { getFormatOrderIndex } from "./documentTypes";
import { getAvailableWorkLanguages } from "./languages";
import { CordataWorkTriple } from "./CordataWorkTriple";
import { LibrarySystem } from "@libry-content/types";

export type WorkTripleIdentifier = { manifestationId: string } | { isbn: string };

type Filters = {
  librarySystem?: LibrarySystem;
};

export class CordataWork {
  private work: Work;

  constructor(work: Work) {
    this.work = work;
  }

  // TODO: Want to not provide work directly, so at a later stage we should be using cordataWork / cordataWorkTriple instead where work is needed (i think)
  /**@deprecated */
  public getRawWork() {
    return this.work;
  }

  public isMovie() {
    return isMovingImageWork(this.work);
  }

  public get id() {
    return this.work.id;
  }

  public getWorkTriple(workTripleIdentifier: WorkTripleIdentifier) {
    const triple = getCordataWemTriples(this.work).find(({ manifestation }) => {
      if ("manifestationId" in workTripleIdentifier) return manifestation.id === workTripleIdentifier.manifestationId;

      return manifestation.isbn.some((isbn) => normalizeIsbn(isbn) === normalizeIsbn(workTripleIdentifier.isbn));
    });

    if (!triple) return undefined;
    return new CordataWorkTriple(triple);
  }

  public getWorkTriples(filters?: Filters) {
    const workTriples = getCordataWemTriples(this.work).map((triple) => new CordataWorkTriple(triple));
    if (filters)
      return workTriples.filter((triple) => {
        //TODO: filter out e-medium triples as we do not support it yet. E-medium appears in global search, and therefore appears in bmdb-sync jobs. Remove when appropriate
        const isNotEMedium = triple.documentType?.format !== "E-lydbok" && triple.documentType?.format !== "E-bok";
        // check if triple is in catalogue if library system is provided
        const isInCatalogue = !filters?.librarySystem || triple.isInCatalogue(filters.librarySystem);
        return isInCatalogue && isNotEMedium;
      });
    return workTriples;
  }

  public get contributors() {
    return this.work.contributors;
  }

  public get workYear() {
    return this.work.workYear;
  }

  public get title() {
    return this.work.title;
  }

  public get formattedTitle() {
    return formatIsbdTitle(this.work);
  }

  public get genres() {
    return this.work.genres;
  }

  public get subjects() {
    return this.work.subjects;
  }

  public get summary() {
    return this.work.summary;
  }

  public get workTypes() {
    return this.work.workTypes;
  }

  public getAvailableWorkLanguages() {
    return getAvailableWorkLanguages(this.work);
  }

  public getDocumentTypes(filters?: Filters) {
    const workTriples = this.getWorkTriples(filters);
    const documentTypes = sift(workTriples.map((triple) => triple.documentType));
    return sort(documentTypes, ({ format }) => getFormatOrderIndex(format));
  }

  public getMediaTypeLabels(filters?: Filters) {
    const workTriples = this.getWorkTriples(filters);
    return unique(sift(workTriples.map((triple) => triple.mediaTypeLabel)));
  }

  get illustratedTag() {
    const documentTypes = this.getDocumentTypes();
    if (!documentTypes.some(({ format }) => ["bok", "ebok"].includes(format?.toLowerCase().replace("-", "")))) {
      return undefined;
    }

    const illustratedBookGenres = [
      "1166092", //bildebøker
      "1166093", //pekebøker
      "1103158", //Kunstnerbøker,
      "1453728", //Bilderomaner
    ];
    const comicGenres = [
      "1166096", //Tegneserier
      "1166043", //Grafiske romaner
    ];

    const illustratedWorkGenres = "1203040";

    if (
      this.work.genres?.some((genre) => {
        return illustratedBookGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
      })
    ) {
      return "Illustrert bok";
    }

    if (
      this.work.genres?.some((genre) => {
        return comicGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
      })
    ) {
      return "Tegneserie";
    }

    if (
      this.work.genres?.some((genre) => {
        return illustratedWorkGenres === genre.identifiers.bibbiAuthorityId;
      })
    ) {
      return "Illustrert verk";
    }

    return undefined;
  }
}
