import { CordataWemTriple } from "@biblioteksentralen/bmdb-search-client";
import { Expression, formatIsbdTitle, Manifestation } from "@biblioteksentralen/cordata";
import { getDocumentTypeLabel } from "./documentTypes";
import { CordataWork } from "./CordataWork";
import { chooseCoverImageQuality } from "./coverImage";
import { CordataLanguageCode, handleLanguages } from "./languages";
import { LibrarySystem } from "@libry-content/types";
import { slugifyString } from "@biblioteksentralen/utils";

export class CordataWorkTriple {
  public work: CordataWork;
  private expression: Expression;
  private manifestation: Manifestation;
  public isAggregateManifestation: boolean;

  constructor(workTriple: CordataWemTriple) {
    this.work = new CordataWork(workTriple.work);
    this.expression = workTriple.expression;
    this.manifestation = workTriple.manifestation;
    this.isAggregateManifestation = workTriple.isAggregateManifestation;
  }

  // TODO: Want to not provide manifestation directly, so at a later stage we should be using workTriple instead where manifestation is needed (i think)
  /**@deprecated */
  public getRawManifestation() {
    return this.manifestation;
  }

  // TODO: Maybe we should remove this later, and handle such differently?
  /**@deprecated */
  public getSortableManifestation = (
    preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
  ): SortableManifestation => ({
    ...this.manifestation,
    collections: this.manifestationCollections,
    expression: {
      ...this.expression,
      languages: handleLanguages(preferredLanguageCodeOrder)(this.languages),
      collections: this.expressionCollections,
    },
  });

  public get manifestationId() {
    return this.manifestation.id;
  }

  public getPath() {
    return `/samling/verk/${this.work.id}/utgave/${slugifyString(this.title.mainTitle)}_${this.manifestation.id}`;
  }

  public get coverImageUrl() {
    return chooseCoverImageQuality(this.manifestation.coverImage, "thumbnail");
  }

  public get expressionContributors() {
    return this.expression.contributors;
  }

  public get manifestationContributors() {
    return this.manifestation.contributors;
  }

  public get mainContributor() {
    return [...this.work.contributors, ...this.expression.contributors, ...this.manifestation.contributors].find(
      ({ isMainContributor }) => isMainContributor
    );
  }

  public get publicationYear() {
    return this.manifestation.publicationYear;
  }

  public get isbns() {
    return this.manifestation.isbn;
  }

  public get languages() {
    return this.expression.languages;
  }

  public get title() {
    return this.manifestation.title;
  }

  public get formattedTitle() {
    return formatIsbdTitle({ title: this.title });
  }

  public get documentType() {
    return this.manifestation.documentType;
  }

  public get documentTypeLabel() {
    const documentType = this.documentType;
    if (!documentType) return undefined;
    const { medium, format } = documentType;
    if (medium === "strømningsbar video") return "Strømmevideo";
    if (medium && ["Film", "Musikk", "Dataspill"].includes(format)) return medium;
    if (medium === "kassett") return `Kassett ${format}`;
    return format;
  }

  public get signatures() {
    return this.manifestation._signatures;
  }

  public get platforms() {
    return this.expression.platforms;
  }

  public get numberOfPlayers() {
    return this.expression.numberOfPlayers;
  }

  public get origin() {
    return this.manifestation._origin;
  }

  public get publicationStatements() {
    return this.manifestation.publicationStatements;
  }

  public get relatedExpressions() {
    return this.manifestation.expressions;
  }

  public get ean() {
    return this.manifestation.ean;
  }

  public get binding() {
    return this.manifestation.binding;
  }

  public get expressionCollections() {
    return this.expression.collections;
  }

  public get manifestationCollections() {
    return this.manifestation.collections;
  }

  public get collections() {
    return [...this.manifestationCollections, ...this.expressionCollections];
  }

  public getLocalRecord(librarySystem: LibrarySystem | undefined) {
    if (!librarySystem?.database) return undefined;
    return this.manifestation.libraryCatalogueRecords?.find((record) => record.catalogueId === librarySystem.database);
  }

  public get mediaTypeLabel() {
    const documentType = this.documentType;
    const illustratedTag = this.work.illustratedTag;
    const platformLabel = this.platformsLabel;

    const documentTypeLabel = documentType ? getDocumentTypeLabel(documentType) : undefined;
    const mediaTypeLabel = illustratedTag ?? platformLabel ?? documentTypeLabel;
    return mediaTypeLabel;
  }

  public isInCatalogue(librarySystem: LibrarySystem) {
    //check if manifestation has a record for the catalogue
    const catalogueIdMatch = this.manifestation.libraryCatalogueRecords?.find(
      (libraryCatalogueRecord) => libraryCatalogueRecord.catalogueId === librarySystem.database
    );

    //if catalogueId is provided and the catalogue record is for filmoteket, check if the catalogueId has the filmoteket-manifestation
    const filmoteketMatch = this.manifestation.libraryCatalogueRecords?.find(
      (libraryCatalogueRecord) =>
        libraryCatalogueRecord.catalogueId === "filmoteket" &&
        libraryCatalogueRecord.libraryIds?.find((libraryId) => libraryId === librarySystem.database)
    );

    if (catalogueIdMatch || filmoteketMatch) {
      return true;
    }

    return false;
  }

  public get platformsLabel() {
    return this.expression.platforms?.length
      ? this.expression.platforms.map((platform) => platform.abbreviatedName ?? platform.name).join(" & ")
      : undefined;
  }
}

export type ExpressionSummary<T extends Expression> = Pick<
  T,
  "languages" | "collections" | "contributors" | "title" | "platforms" | "numberOfPlayers"
>;

export type SortableManifestation = Manifestation & {
  isAggregateManifestation?: boolean;
  expression: ExpressionSummary<Expression>;
};
